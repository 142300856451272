// Imports
import React from 'react';
import { Helmet } from 'react-helmet';

// ======== Component ========
const SEO = props => {
	const {
		title,
	} = props;

	return (
	// Supports all valid head tags: title, base, meta, link, script, noscript, and style tags.
	// Supports attributes for body, html and title tags.
		<Helmet htmlAttributes={{ lang: 'en' }}>
			<meta charSet={'utf-8'} />
			<title>{title || 'Balanced Body UK'}</title>
			<meta name={'description'} content={'Balanced Body UK product inventory'} />
			<link rel={'canonical'} href={'https://balancedbodyequipment.uk/'} />
			<script
				// Add Gorgias chat widget
				id={'gorgias-chat-widget-install-v3'}
				src={'https://config.gorgias.chat/bundle-loader/01JF8QNKD649ERWA9HK71XX0A0'}
			/>
			<script
				// Add Gorgias campaign bundle
				id={'convert-bundle-loader'}
				src={'https://bundle.dyn-rev.app/loader.js?g_cvt_id=145965dd-d0eb-4a01-a8aa-3c38752af806'}
			/>
		</Helmet>
	);
};

export default SEO;
